<template>
  <div>
    <v-card outlined class="mt-n3" tile>

      <Dashboard v-if="selectedOrganization" v-bind:organization="selectedOrganization"
        @goToOrganizationsList="backToOrganizations($event)"></Dashboard>


      <v-data-table v-else :headers="headers" :items="organizationsWithIndex" sort-by="title" class="elevation-1"
        loading-text="Loading... Please wait" :search="search" :custom-filter="filterText">
        <template v-slot:top>
          <v-toolbar flat class="pt-2">
            <div class="mt-5" align="left">
              <v-toolbar-title class="
                info--text
                text-uppercase
                font-weight-bold
                text--darken-1
                justify-center
              ">
                <v-icon class="mr-2 mt-n1" color="info">mdi-home-city</v-icon>Organisations
              </v-toolbar-title>
              <v-divider class="mx-4" vertical></v-divider>
            </div>
            <v-spacer></v-spacer>
            <div class="float-right">
              <v-tooltip bottom per>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" dark class="text-capitalize my-auto ml-1" elevation="3" @click="createOrgModal"
                    v-bind="attrs" v-on="on">
                    <v-icon class="m-2">mdi-home-plus</v-icon>
                    <span>Create Org</span>
                  </v-btn>
                </template>
                <span>New organisation</span>
              </v-tooltip>

            </div>
          </v-toolbar>

          <v-progress-linear indeterminate color="info" v-if="loading"></v-progress-linear>

          <v-divider></v-divider>

        </template>

        <template v-slot:[`item.organization`]="{ item }">
          <span @click="selectOrganization(item)">{{ item.legal_name }}</span>
        </template>

        <template v-slot:[`item.facility`]="{ item }">
          <v-list dense>
            <v-list-item
              v-for="(facility, i) in item.facility"
              :key="facility.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ ++i + '. ' + facility.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <template v-slot:[`item.more`]="{ item }">
          <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="selectOrganization(item)">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.edit`]="{ item }">
          <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="editItem(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
          <v-dialog v-model="showDeleteDialog" persistent width="400">
            <v-card>
              <v-card-title class="headline">
                Proceed with caution!
                <span class="float-right">
                  <v-icon class="mdi mdi-alert-circle-outline"></v-icon>
                </span>
              </v-card-title>
              <v-card-text>
                You are about to delete an organization,
                are you sure you want to delete this item?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showDeleteDialog = false">Cancel</v-btn>
                <v-btn color="error" text @click="deleteOrganization(item)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="error" class="mx-1" fab x-small dark elevation="0" @click="showDeleteDialog = true">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <a class="info--text text--darken-1">
            No Organisations
          </a>
        </template>

      </v-data-table>

    </v-card>
    <!-- Edit modal  -->
    <v-dialog v-model="dialog_org" max-width="600">
      <v-card>
        <v-card-title class="grey--text text-h5">
          Organization
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mb-0 pb-0">
          <v-form ref="form" v-model="valid" lazy-validation class="pt-7 pb-0">

            <v-text-field v-model="orgname" label="Parent Organization" color="info" class="mx-1" outlined dense
              readonly>
            </v-text-field>

            <v-text-field v-model="editedItem.legal_name" label="Name" :rules="rules.required" color="info"
              class="mx-1" outlined dense>
            </v-text-field>

            <v-autocomplete dense v-model="editedItem.admin_id" :items="organizationUsers" item-value="id"
              item-text="attributes.name" label="Location Admin" :error-messages="apiErrors.admin_id" outlined></v-autocomplete>

            <v-radio-group v-model="editedItem.isFacilityManager" class="mt-0">
              <template v-slot:label>
                <div>Can {{ editedItem.legal_name != null ? editedItem.legal_name : 'organization' }} manage a
                  <strong>facility?</strong></div>
              </template>
              <v-radio v-bind:value=1>
                <template v-slot:label>
                  <div>Yes, will manage facility/ies.</div>
                </template>
              </v-radio>
              <v-radio v-bind:value=0>
                <template v-slot:label>
                  <div>No facility/ies to manage.</div>
                </template>
              </v-radio>
            </v-radio-group>

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red darken-1" class="text-capitalize mx-1" dark text @click="dialog_org = false">
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn v-if="!update_org" color="green" dark elevation="0" class="text-capitalize mx-1" :loading="loading"
            @click="save">
            {{ update_org ? 'Update' : 'Save' }}
          </v-btn>

          <v-btn v-else color="green" dark elevation="0" class="text-capitalize mx-1" :loading="loading"
            @click="updateOrganization">
            Update
          </v-btn>

        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- End of modal  -->
  </div>
</template>
<script>
import OrganizationService from "../../services/organization.service";
import Dashboard from "../Organization/Dashboard.vue"

export default {
  components: { Dashboard },
  data: () => ({
    update_org: false,
    loading: true,
    name: "Facilities",
    search: "",
    items: [],
    groups: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    remember: 0,
    timeout: 2000,
    formTitle: "",
    dialog_org: false,
    valid: true,
    can_manage_facility: 0,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "index",
      }, {
        text: "Organisation Name",
        value: "organization",
      },
      {
        text: "Facility",
        value: "facility",
      },
      {
        text: "Parent",
        value: "parent_organization",
      },
      {
        text: "View More Details",
        align: "center",
        value: "more",
        sortable: false,
      },
      {
        text: "Edit",
        align: "center",
        value: "edit",
        sortable: false,
      },
      {
        text: "Delete",
        align: "center",
        value: "delete",
        sortable: false,
      },
    ],
    selectedOrganization: null,
    organizationUsers: [],
    apiErrors: [],
    showDeleteDialog: false,
  }),
  computed: {
    item() {
      return JSON.parse(localStorage.getItem("item"));
    },
    itemData() {
      return JSON.parse(localStorage.getItem("itemData"));
    },
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
    orgname() {
      return this.org.name ?? this.org.legal_name;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },

    organizationsWithIndex() {
      return this.items.map(
        (items, index) => ({
          ...items,
          index: index + 1
        })
      )
    }
  },
  watch: {
    dialog_org(val) {
      val || this.close();
    },
  },
  created() {
    this.getData();
    this.getUsers();
  },
  methods: {

    backToOrganizations() {
      // Set selected organization to null
      this.selectedOrganization = null;
    },

    createOrgModal() {
      this.dialog_org = true;
      this.formTitle = "New organisation";
    },
    getEntriesList(legal_name) {
      console.log("Loading");
      let badges = "";
      if (legal_name.length > 1) {
        legal_name.forEach((element) => {
          badges = badges + element + ", ";
        });
      } else {
        legal_name.forEach((element) => {
          badges = badges + element;
        });
      }

      return badges;
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    // Fetch organization users
    getUsers() {
      OrganizationService.organization_users(this.org.id).then(
        success => {

          this.organizationUsers = success.data ? success.data.data : this.organizationUsers

        },
        error => {
          console.log(error)
          this.organizationUsers = [];
        }
      )
    },
    //fetch data from the database
    getData() {
      let user = JSON.parse(window.localStorage.getItem('user'));

      OrganizationService.personOrganizations(user.id).then(
        (response) => {

          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(item) {
      this.formTitle = 'Update Organization';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.legal_name = item.attributes.legal_name // Object.assign({}, item);
      this.editedItem.isFacilityManager = item.attributes.isFacilityManager;
      this.editedItem.isThirdParty = item.attributes.isThirdParty;
      this.editedItem.id_parent = this.org.id
      if (item.relationships.admin != null) {
        this.editedItem.admin_id = item.relationships.admin.id
      }

      this.update_org = true;
      this.dialog_org = true;
    },
    // deleting the data from the database
    deleteOrganization(item) {
      OrganizationService.delete(item).then(
        (response) => {
          if (response.status === 200) {
            let index = this.items.indexOf(item);
            this.items.splice(index, 1)
            this.$store.dispatch("alert/success", "deleted successfully", response.data.message);
            this.close();
          } else {
            this.$store.dispatch("alert/error", "legal_name not deleted", response.data.message);
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }

          if (error.response.status === 401) {
            this.handleLogout();
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    close() {
      this.update_org = false;
      this.dialog_org = false;
      this.showDeleteDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // create
        this.editedItem.id_parent = this.org.id
        this.editedItem.isFacilityManager = this.editedItem.isFacilityManager != null ? 1 : 0
        OrganizationService.store(this.editedItem).then(
          (response) => {

            if (response.status == 200) {
              this.items.push(this.editedItem);
              this.$store.dispatch("alert/success", this.editedItem.legal_name + " created");
              this.loading = false;
              this.getData();
              this.close();
            } else {
              this.$store.dispatch("alert/error", this.editedItem.legal_name + " not created");
              this.getData();
            }
          },
          (error) => {
            if (error.response.status == 422) {

              this.apiErrors = error.response.data.errors
              this.$store.dispatch(
                "alert/error",
                error.response.data.message
              );
              this.loading = false;
              this.getData();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.getData();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );

      } else {
        this.loading = false;
        this.getData();
      }
    },

    updateOrganization() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        this.editedItem.isFacilityManager = this.editedItem.isFacilityManager != null ? 1 : 0
        OrganizationService.update(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch(
                "alert/success",
                "Item edited successfully",
                response.data.message
              );
              this.loading = false;
              this.getData();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.getData();
              this.loading = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.loading = false;
              this.$store.dispatch(
                "alert/error",
                error.response.data.message
              );
              this.getData();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.getData();
            }
            console.log(error);
            this.loading = false;
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.getData();
      }
    },


    selectOrganization(organization) {
      this.selectedOrganization = organization
    }
  },
};
</script>
